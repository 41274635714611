import Vue from "vue";
import Products from "@/components/widgets/products";
import Suppliers from "@/components/widgets/suppliers";
import Branches from "@/components/widgets/branches";
import Documents from "@/components/widgets/documents";
import Loading from "@/components/widgets/Loading.vue";
import SortedTablePlugin from "vue-sorted-table";
import DatePicker from "vue2-datepicker";
import moment from "moment";
const _ = require("lodash");

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    Loading,
    DatePicker,
    Products,
    Suppliers,
    Documents,
    Branches,
  },
  data() {
    return {
      loading: false, 
      document_id: null,
      branchesOpend: false,
      productOpened: false,
      selectOpened: false,
      document_status: null,
      form: {
        license_plate: "",
        car_number: "",
        document_no: "",
        date: new Date(),
        type: 1,
        until: null,
        payment_terms: 1,
        note: "",
        branch_id: null,
        more_weight: "",
      },
      fields: [
        {
          key: "detail.lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.invoice_date",
          label: "Invoice date",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.can_pick",
          label: "สาขาสั่งได้",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "detail.price",
          label: "ราคาต่อกิโล/Kg",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "detail.note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "detail.select_branch",
          label: "เลือกสาขา (วางบิล)",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "detail.destination",
          label: "เลือกสาขา (รับของ)",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        },
      ],
      branchesFields: [
        {
          key: "code",
          label: "รหัสสาขา",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2 py-1",
        },
        {
          key: "name",
          label: "ชื่อสาขา",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2 py-1",
          sortable: true,
        },
        {
          key: "address",
          label: "ที่อยู่",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-1",
        },
      ],
      allBranches: [],
      branches: [],
      products: [],
      reqProducts: [],
      supplier: {},
      orderStatus: [
        { text: "รอเข้า", value: "awaiting" },
        { text: "พร้อมสั่ง", value: "readyfororder" },
        { text: "กำลังส่ง", value: "transferring" },
        { text: "รับแล้ว", value: "delivered" },
      ],
    };
  },
  methods: {
    getSelectedBranches() {
      return this.branches;
    },
    getAllBraches(){
      return this.allBranches
    },
    previous() {
      this.$router.push('/order-centric')
    },
    onselectProduct() {
      this.productOpened = !this.productOpened;
    },
    onselectSupplier() {
      this.selectOpened = !this.selectOpened;
    },
    onselectBranches() {
      this.branchesOpend = !this.branchesOpend;
    },
    async onSubmitForm() {
      if (this.branches.length === 0) {
        this.onExceptionHandler("กรุณาเลือกสาขา");
        return
      }

      if (this.products.length === 0) {
        this.onExceptionHandler("กรุณาเลือกม้วน");
        return
      }

      this.loading = true;
      const formData = new FormData();

      formData.append("document_no", this.form.document_no);
      formData.append("date", moment(this.form.date));
      formData.append("type", this.form.type);
      formData.append(
        "until",
        this.form.until != null ? moment(this.form.until) : null
      );
      formData.append("payment_terms", this.form.payment_terms);
      formData.append("note", this.form.note);
      formData.append("license_plate", this.form.license_plate);
      formData.append("car_number", this.form.car_number);
      formData.append("more_weight", this.form.more_weight);

      this.form.branch_id = this.branches[0].id;

      formData.append("branch_id", this.form.branch_id);
      formData.append("branches", JSON.stringify(this.branches));

      this.products.forEach((p) => {
        delete p.detail;
      });

      formData.append("products", JSON.stringify(this.products));

      try {
        await Vue.prototype.$axios.post(
          `${Vue.prototype.$host}/api/v1/document/preemption/edit/${this.$route.params.id}`,
          formData
        );

        this.loading = false;
        this.$swal({
          width: "29rem",
          padding: "1.825em",
          html:
            '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
            '<div class="d-flex flex-column">' +
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
            "</div>" +
            "</div>",
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: "swal2-custom-rounded",
            closeButton: "text-3xl swal2-custom-close",
            actions: "justify-content-end",
            contant: "d-flex flex-row justify-content-around px-2",
            title: "d-none",
            confirmButton:
              "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton:
              "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          },
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCloseButton: true,
        }).then((result) => {
          this.$router.push('/order-centric')
        });
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onreset() {
      this.products = [];
      this.supplier = {};
    },
    firstOrupdate(items) {
      items.forEach((i) => {
        i.detail = i;
      });

      if (this.products.length === 0) {
        this.products = items;
      } else {
        for (let index = 0; index < items.length; index++) {
          const element = items[index].detail;
          const item = _.find(this.products, { uuid: element.uuid });
          if (!item) {
            this.products.push(element);
          }
        }
      }
    },
    onRemoveBranches(id) {
      const index = _.findIndex(this.branches, function (o) {
        return o.uuid == id;
      });
      this.branches.splice(index, 1);
    },
    onRemoveProduct(id) {
      const index = _.findIndex(this.products, function (o) {
        return o.detail.uuid == id;
      });
      this.products.splice(index, 1);
    },
    onInitProducts(event) {
      this.productOpened = false;
      if (event.status) {
        this.firstOrupdate(event.data);
      }
    },
    onInitBranches(event) {
      this.branchesOpend = false;
      if (event.status) {
        let items = event.data;
        if (this.branches.length === 0) {
          this.branches.push(items);
        } else {
          const item = _.find(this.branches, { uuid: items.uuid });
          if (!item) {
            let data = {
              preemption_document_id: this.document_id,
              branch_id: event.data.id,
              detail: event.data,
            };
            this.branches.push(data);
          }
        }
      }
    },
    onInitSupplier(event) {
      this.selectOpened = false;
      if (event.status) {
        this.supplier = event.data;
      }
    },
    async getBranches() {
      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.allBranches = result.data
    },
  },
  async created() {
    try {
      this.getBranches()

      const id = this.$route.params.id;
      if (id) {
        const res = await this.$axios.get(
          this.$host + "/api/v1/document/preemption/" + id,
          {
            headers: {},
          }
        );
        const data = res.data;
        const preemption = data.data;
        const products = data.products;

        this.document_id = data.id;

        products.forEach((p) => {
          p.detail.selected_branch = p.branch_id ? parseInt(p.branch_id) : null;
          p.selected_branch = p.branch_id ? parseInt(p.branch_id) : null;
          p.id = parseInt(p.product_id);
        });
        const branches = data.branches;

        this.form.document_no = "PO-" + preemption.document_no;
        this.form.date = new Date(preemption.date);
        this.form.type = preemption.type;
        this.form.payment_terms = preemption.payment_terms;
        this.form.until = preemption.until ? new Date(preemption.until) : null;
        this.form.car_number = preemption.car_number;
        this.form.license_plate = preemption.license_plate;
        this.form.more_weight = preemption.more_weight;
        this.form.note = preemption.note;
        this.document_status = preemption.status

        this.branches = branches;
        this.products = products;
      }
    } catch (error) {
      this.loading = false;
      this.onExceptionHandler(error);
    }
  },
};
